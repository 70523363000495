import React from 'react';
import { Link } from 'gatsby';
import Container from '../Container';
import heroStyles from './hero.module.scss';

const Hero = () => {
    return (
        <div className={heroStyles.herocontainer}>
        <Container>
            <h1 className={heroStyles.herotitle}>Abonează-te la newsletter-ul MintWebTuts</h1>
            <h2 className={heroStyles.herosubtitles}>
                Vei primii informații utile și tutoriale despre programare, web development, web design și despre cum 
                să-ți găsești un job în domeniul IT în fiecare săptămână pe email</h2>
                <Link to='/subscribe' className={heroStyles.subscribebutton}>Ma abonez</Link>
        </Container>
        </div>
    );
}
export default Hero;