import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import Layout from '../shared/Layout';
import CenterLayout from '../shared/CenterLayout';
import Hero from '../components/Hero';
import Head from '../shared/Head';
import homeStyles from './index.module.scss';

const Home = () => {

  const data = useStaticQuery(graphql`
    query {
        allMarkdownRemark (
          limit: 10,
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              frontmatter {
                title,
                date
              },
              fields {
                slug
              },
              excerpt,
              html
            }
          }
        }
    }
    `);

    const posts = data.allMarkdownRemark.edges.map((post) => {
        return {
            title: post.node.frontmatter.title,
            date: post.node.frontmatter.date,
            excerpt: post.node.excerpt,
            html: post.node.html,
            slug: post.node.fields.slug
        };
    });

  return (
    <Layout home={true}>
      <Head title={`Home`}/>
      <Hero />
      <CenterLayout>
        <h1 className={homeStyles.articlestitle}>Ultimele articole</h1>
        {posts.map((post, index) => {
                return (
                <div className={homeStyles.article} key={index}>
                    <span className={homeStyles.postdate}>{post.date}</span>
                    <h1 className={homeStyles.articletitle}><Link to={`/blog/${post.slug}`}>{post.title}</Link></h1>
                    <div>{post.excerpt}</div>
                    <div className={homeStyles.articlefooter}>
                    <Link className={homeStyles.readmore} to={`/blog/${post.slug}`}>
                      <span><FontAwesomeIcon icon={faCoffee}/></span>Citeste mai mult</Link>
                    </div>
                </div>
                );
            })} 
      </CenterLayout>
    </Layout>
  );
};

export default Home;
